<template>
  <h2>經紀費管理</h2>

  <div class="my-4 text-end">
    <router-link :to="`/admin/income`">返回列表頁</router-link>
  </div>

  <form action="" @submit.prevent="update()">
    <div class="card">
      <h5 class="card-header">編輯經紀費</h5>
      <div class="card-body px-5 mt-3">
        <div class="my-3 mb-5 text-center">
          <h3>{{ year }} 年 / {{ month }} 月</h3>
        </div>
        <div class="row">
          <div class="col">
            <label class="form-label h6">會員</label>
            <div>{{ name }}</div>
          </div>
          <div class="col">
            <label class="form-label h6">經紀費金額</label>
            <input
              type="number"
              class="form-control"
              v-model.number="brokerageFees"
              required
              min="1"
            />
          </div>
          <div class="col">
            <label class="form-label h6">上台數</label>
            <input
              type="number"
              class="form-control"
              v-model.number="hoursOnStage"
              required
              min="0.01"
              step="0.01"
            />
          </div>
          <div class="col">
            <label class="form-label h6">上班天數</label>
            <input
              type="number"
              class="form-control"
              v-model.number="daysAtWork"
              required
              min="1"
            />
          </div>
          <div class="col">
            <label class="form-label h6">薪資金額</label>
            <input
              type="number"
              class="form-control"
              v-model.number="salary"
              required
              min="1"
            />
          </div>
        </div>
        <div class="mt-5 mb-3 text-center">
          <button type="submit" class="btn btn-success">儲存</button>
        </div>
      </div>
    </div>
  </form>

  <!-- <pre>{{ docId }}</pre>
  <pre>{{ memberId }}</pre> -->
</template>

<script>
import { brokerageFeesService } from "../../../services/brokerageFeesService.js";
export default {
  data() {
    return {
      docId: "",
      memberId: "",
      name: "",
      brokerageFees: "", //經紀費金額
      hoursOnStage: "", //上台時數
      daysAtWork: "", //上班天數
      salary: "", //薪資金額
      year: "",
      month: "",
    };
  },
  methods: {
    async update() {
      const result = await brokerageFeesService.updateOneMemberFees(
        this.docId,
        this.memberId,
        this.brokerageFees,
        this.hoursOnStage,
        this.daysAtWork,
        this.salary
      );
      if (result) {
        alert("儲存成功");
        this.$router.push({ name: "income" });
      } else {
        alert("儲存失敗");
      }
    },
  },
  async created() {
    this.docId = this.$route.params.docId;
    this.memberId = this.$route.params.memberId;

    const data = await brokerageFeesService.getOneMemberFees(
      this.docId,
      this.memberId
    );

    if (data) {
      this.name = data.memberData.name;
      this.brokerageFees = data.brokerageFees;
      this.hoursOnStage = data.hoursOnStage;
      this.daysAtWork = data.daysAtWork;
      this.salary = data.salary;
      this.year = data.year;
      this.month = data.month;
    } else {
      alert("查無此會員");
      this.$router.push({ name: "income" });
    }
  },
};
</script>
